<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="媒介订单列表" />

			<div class="ct_box">
				<orderNav num="order_num" @navTab="navTab" :navId="mediaOrder.category_id" />
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker value-format="timestamp" @change="getList()" v-model="time" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<div class="order_search flex">
							<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
							<el-input class="order_search_inp" v-model="title" placeholder="输入标题/订单号"
								@keyup.enter.native="getList"></el-input>
							<button class="search_but" @click="getList">搜索</button>
						</div>
						<medium_search @searchMedium="searchMedium" />
					</div>
					<orderStatus ref="orderStatusTab" type="order_status_num" @statusTab="statusTab"
						:status_list="status_list" :category_id="mediaOrder.category_id"
						:order_status="mediaOrder.order_status" />
				</div>
				<table class="table">
					<thead>
						<tr>
							<td>订单号</td>
							<td>标题</td>
							<!-- <td>媒介类别</td> -->
							<td>媒介名称</td>
							<td v-if="mediaOrder.category_id == 2 || mediaOrder.category_id == 7">
								所属平台</td>
							<td>金额</td>
							<td>状态</td>
							<td>创建时间</td>
							<td>发布时间</td>
							<td>撤稿时间</td>
							<td>撤稿原因</td>
							<td>操作</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="item.id">
							<td>
								{{ item.sn }}
								<timeLimit :limitNum="item.limit_time" />
							</td>
							<td>
								<td_remark :text="item.title" />
								<span class="look" @click="lookShow(item.id)">[查看]</span>
							</td>
							<!-- <td>{{ item.category_title }}</td> -->
							<td>{{ item.medium_title }}</td>
							<td v-if="mediaOrder.category_id == 2 || mediaOrder.category_id == 7">
								{{ item.portal_title }}</td>
							<td>￥{{ item.order_price }}</td>
							<td :style="{
		color: item.order_status == 0 ? '#000' : item.order_status == 1 ? '#FF8400' : item.order_status == 2 ?
			'#00B052' : item.order_status == 3 ? '#FF8400' : '#FF0000'
	}">
								{{ item.order_status == 0 ? '未处理' : item.order_status == 1 ? '发布中' : item.order_status
		== 2
		? '已出稿' : item.order_status == 3 ? '申请退款' : item.order_status == 4 ? '拒绝退款' :
			item.order_status == 5 ? '撤稿' : item.order_status == 6 ? '申请撤稿' : item.order_status == 7
				?
				'拒稿' : item.order_status == 8 ? '退稿' : '拒绝撤稿' }}
								<!-- {{ statusList[item.order_status + 1].title }} -->

								<span v-show="item.order_status == 0 || item.order_status == 1"
									style="color: #FF0000;">{{
		item.is_limit == 1 ? '[已超时]' : '' }}</span>
								<td_remark v-if="item.reason4.reason" :text="'原因:' + item.reason4.reason" />
							</td>
							<td>{{ item.createtime }}</td>
							<td>{{ item.publish_time ? item.publish_time : '暂无' }}</td>
							<td>
								{{ item.withdraw_time ? item.withdraw_time : '暂无' }}
							</td>
							<td>
								<td_remark v-show="item.reason4 != '' && item.reason == ''" :text="item.reason4.id" />
								<td_remark v-show="item.reason4 != '' && item.reason == ''"
									:text="item.reason4.reason" />
								<td_remark v-show="item.reason != '' && item.reason.id != '请选择'"
									:text="item.reason.id" />
								<td_remark v-show="item.reason != ''" :text="item.reason.reason" />
							</td>
							<td class="operation">
								<span
									v-if="item.order_status == 2 || (item.order_status == 3 && item.order_url != '') || (item.order_status == 4 && item.order_url != '')"
									@click="LinkShow(item)">查看链接</span>
								<span
									v-if="(item.order_status == 0 && item.is_limit) || (item.order_status == 1 && item.is_limit) || item.order_status == 0"
									@click="withdraw(item)">撤稿</span>
								<!-- <span v-if="item.order_status == 0" @click="orderStatus(item, 5)">撤稿</span> -->

								<span
									v-if="(item.order_status == 1 && !item.is_limit) || (item.order_status == 2 && !item.is_limit)"
									@click="orderStatus(item, 3)">申请退款</span>

								<!-- <span @click="redispatchFn(item)" v-if="item.order_status == 2">再发布</span> -->
								<span @click="orderShow(item)" v-if="item.order_status == 2">提交工单</span>
								<span v-if="item.order_status == 4 || item.order_status == 9 || item.order_status == 7"
									@click="serviceShow = true">联系客服</span>
								<span @click="Over(item)" v-if="item.order_status == 3">取消退款</span>
								<span v-if="item.order_status == 0" @click="goEdit(item)">修改稿件</span>
								<span @click="issue(item)"
									v-if="item.order_status == 2 || item.order_status == 5 || item.order_status == 8">重新发布</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :total_order_price="total_order_price" :count="count"
					:total_page="total_page" />
			</div>

		</div>
		<order_link_poup :linkList="linkList" :linkShow="linkShow" @close="linkShow = false" />
		<el-dialog title="提交工单" :append-to-body="true" :visible.sync="order" :show-close="false">
			<p class="workOrder_title">请描述您的问题</p>
			<el-input type="textarea" :rows="6" resize="none" v-model="work">
			</el-input>
			<div class="flex" style="margin-top: 2rem; justify-content: flex-end">
				<button class="copy_but" @click="orderUp">确认提交</button>
			</div>
		</el-dialog>
		<el-dialog class="service_poup" title="联系客服" :append-to-body="true" :visible.sync="serviceShow"
			:show-close="false">
			<!-- <p class="workOrder_title">客服电话：83730126/82821214</p> -->
			<img :src="$user_info.custom_info.avatar" alt="" style="max-width: 40%;margin: 0 auto;display: block;">
			<p class="workOrder_title">微信扫面上方二维码添加专属客服经理</p>

		</el-dialog>
		<rejectionPoup :order_type="popupObj.type === false ? popupObj.type : ''" :himt="popupObj.himt"
			:order_status="order_status" :placeholder="popupObj.placeholder" :reason_title="popupObj.title"
			:title="popupObj.title" :rejectShow="rejectShow" :rejectItem="rejectItem" @rejectComfirm="rejectComfirm"
			@rejectClose="rejectShow = false" />
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import rejectionPoup from '../../components/rejectionPoup.vue'
import timeLimit from '../../components/timeLimit.vue'
import { timeCut } from '../../util/timeCut'
import { mapState } from 'vuex'
import orderNav from '../../components/orderNav.vue'
import Td_remark from '../../components/td_remark.vue'
import medium_search from '@/components/medium_search.vue'
import orderStatus from '@/components/orderStatus.vue'
import order_link_poup from '@/components/order_link_poup.vue'
export default {
	components: {
		Title,
		timeLimit,
		rejectionPoup,
		orderNav,
		medium_search,
		orderStatus,
		order_link_poup

	},
	computed: {
		...mapState(['mediaOrder', 'orderId'])
	},
	created() {
		if (this.$route.query.time) {
			this.status = {
				id: 6,
				title: '限时订单'
			}
		} else if (this.$route.query.status && this.$route.query.status == '退稿') {
			this.status = {
				id: 5,
				title: '撤稿'
			}
		} else if (this.$route.query.status && this.$route.query.status == '已处理') {
			this.status = {
				id: 2,
				title: '已出稿'
			}
		}
		// 取媒体类别
		this.curlGet('/api/medium_category/list', {
		}).then(res => {
			if (res.data.code) {
				this.resourceList = res.data.data
				console.log(this.resourceList);
			}
		})
		this.$store.commit('childTab', '媒介订单')
		this.getList()
	},
	data() {
		return {
			timer: null,
			time: '',
			title: '',
			resourceList: [],
			tableData: [],
			linkShow: false, //查看链接弹窗
			order: false, //提交工单弹窗
			orderItem: {}, //工单信息
			rejectItem: {},
			rejectShow: false,
			serviceShow: false, //客服
			work: '',
			total_page: 0, //分页总页数
			count: 0, //总条数
			total_order_price: 0, //当前页数所有条数价钱总和
			page: 1,
			medium_name: '',
			linkList: {},
			fullscreenLoading: false, //loding
			order_status: '',
			popupObj: {
				himt: '',
				title: '',
				reason_title: '',
				placeholder: '',

			},
			status_list: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '未处理',
				},
				{
					id: 1,
					title: '发布中',
				},
				{
					id: 2,
					title: '已出稿',
				},
				{
					id: 3,
					title: '申请退款',
				},
				{
					id: 4,
					title: '拒绝退款',
				},
				{
					id: 5,
					title: '撤稿',
				},
				{
					id: 6,
					title: '限时订单',
				},
			],

		}
	},
	methods: {
		statusTab(item) {
			this.$store.state.mediaOrder.order_status = item
			this.page = this.pageChangeOne()
			this.getList()
		},
		searchMedium(title) {
			this.medium_name = title
			this.page = this.pageChangeOne()
			this.getList()
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.getList()
		},
		navTab(id) {
			this.$store.state.mediaOrder.category_id = id
			console.log(this.$store.state);
			setTimeout(() => {
				this.$refs.orderStatusTab.getNum(this.$store.state.mediaOrder.category_id)
			}, 300)
			this.getList()

		},
		statusShow(item) {
			this.status = item
			this.page = this.pageChangeOne()
			this.getList()
		},
		LinkShow(item) {
			this.linkList = {
				title: item.title,
				order_urls: item.order_urls,
				medium_title: item.medium_title
			}
			this.linkShow = true

		},

		rejectComfirm() {
			this.rejectShow = false
			this.getList()
		},
		// 提交工单
		orderShow(item) {
			this.order = true
			this.orderItem = item
			console.log(this.orderItem);
		},
		// 提交
		orderUp() {
			if (this.work != '') {
				this.curlPost('/api/users/work/add', {
					order_id: this.orderItem.id,
					desc: this.work
				}).then(res => {
					console.log(res);
					if (res.data.code) {
						this.getList()
						this.work = ''
						this.order = false
						return this.$message({
							message: '提交成功',
							type: 'success',
						})

					} else {
						return this.$message({
							message: '提交失败',
							type: 'warning',
						})
					}
				})
			}
		},
		lookShow(item) {
			// this.$router.push({
			// 	path: '/user/lookdetails',
			// 	query: {
			// 		id: item
			// 	}
			// })
			const { href } = this.$router.resolve({
				name: 'lookdetails',
				query: {
					id: item
				}
			})
			window.open(href, '_blank',)
		},
		// 申请退款  撤稿要求
		orderStatus(item, status) {
			this.rejectItem = JSON.parse(JSON.stringify(item))
			this.order_status = status
			this.rejectShow = true
			this.popupObj = {
				himt: '',
				title: '',
				placeholder: '',

			}
			if (status == 3) {
				this.popupObj.type = false
				this.popupObj.himt = '提示：此稿件确定要申请退款吗?'
				this.popupObj.title = '申请退款'
				this.popupObj.placeholder = '请输入退款原因'
				this.reason_title = '退款原因'
			} else {
				this.popupObj.himt = '提示：此稿件确定要撤稿吗?'
				this.popupObj.title = '撤稿'
				this.popupObj.placeholder = '请输入撤稿原因'
				this.reason_title = '撤稿原因'
			}
		},

		// 状态为未处理的稿件走不需要提交input的接口
		withdraw(item) {

			this.$confirm('是否撤稿?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlPost('/api/users/order/edit', {
					id: item.id,
					status: 5,
					url: item.order_url,
				}).then(res => {

					if (res.data.code) {
						this.getList()
						this.$message({
							type: 'success',
							message: '已撤稿'
						});
					}
				})
			})
		},
		// 重新发布
		issue(item) {
			this.$confirm('是否重新发布?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				// this.curlPost('/api/users/order/edit', {
				// 	id: item.id,
				// 	status: 0,
				// 	url: item.order_url,
				// }).then((res) => {
				// 	console.log(res)

				// 	if (res.data.code) {
				// 		this.resource = {
				// 			id: 0,
				// 			title: '未处理'
				// 		}
				// 		this.getList()
				// 	}
				// })
				// this.$message({
				// 	type: 'success',
				// 	message: '待发布',
				// })
				this.$router.push({
					path: '/user/SetMenu/fill',
					query: {
						id: item.category_id,
						mediaOrderId: item.id
					}
				})
			})
		},
		// 编辑稿件
		goEdit(item) {
			console.log(typeof (item.category_id));
			switch (item.category_id) {
				case 1:
					this.$store.commit('childTab', '资讯门户发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break
				case 2:
					this.$store.commit('childTab', '自媒体发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break
				case 3:
					this.$store.commit('childTab', '纸媒介发布')
					this.$router.push({
						path: '/user/SetMenu/EditEditFill?id=' + item.id,
					})
					break
				case 4:
					this.$store.commit('childTab', '公众号发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break
				case 5:
					this.$store.commit('childTab', '微博发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break
				case 6:
					this.$store.commit('childTab', '小红书发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break
				case 7:
					this.$store.commit('childTab', '短视频发布')
					this.$router.push({
						path: '/user/EditFill?id=' + item.id,
					})
					break

				default:
					break;
			}
			// this.$router.push({
			// 	path: '/user/EditFill',
			// 	query: {
			// 		id: item.id
			// 	}
			// })
		},
		// 取消退款
		Over(item) {
			console.log(item);
			this.$confirm('是否取消退款?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlPost('/api/users/order/edit', {
					id: item.id,
					status: 1,
					url: item.order_url,
				}).then(res => {
					console.log(res);
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '待发布 '
						});
						this.getList()
					}
				})
			})
		},
		// 再发布
		redispatchFn(item) {
			console.log(item);
			switch (item.category_id) {
				case 1:
					this.$store.commit('childTab', '资讯门户发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break
				case 2:
					this.$store.commit('childTab', '自媒体发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break
				case 3:
					this.$store.commit('childTab', '纸媒介发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break
				case 4:
					this.$store.commit('childTab', '公众号发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break
				case '5':
					this.$store.commit('childTab', '微博发布')
					this.$router.push({
						path: '/user/EditFill?itemId=' + item.id,
					})
					break
				case '6':
					this.$store.commit('childTab', '小红书发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break
				case '7':
					this.$store.commit('childTab', '短视频发布')
					this.$router.push({
						path: '/user/Orfill?itemId=' + item.id,
					})
					break

				default:
					break;
			}
		},
		getList() {
			this.fullscreenLoading = true
			let data = {}
			data.page = this.page
			data.limit = 20
			data.category_id = this.$store.state.mediaOrder.category_id

			if (this.mediaOrder.order_status.id !== '') {
				data.order_status = this.mediaOrder.order_status.id
			}
			if (this.title != '') {
				data.title = this.title
			}
			if (this.medium_name !== '') {
				data.medium_name = this.medium_name
			}
			if (this.time) {
				data.start_time = this.time[0] / 1000
				data.end_tiem = this.time[1] / 1000
			}

			this.curlGet('/api/users/order/list', data).then(res => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					if (data.order_status == 2) {
						this.total_order_price = res.data.data.total_order_price
					} else {
						this.total_order_price = 0
					}
					this.tableData.forEach((item, index) => {
						if (this.tableData[index].withdraw_time) {
							this.tableData[index].withdraw_time = timeCut(this.tableData[index].withdraw_time * 1000)
						}
						this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
						if (this.tableData[index].publish_time) {
							this.tableData[index].publish_time = timeCut(this.tableData[index].publish_time * 1000)
						}
					})
					this.fullscreenLoading = false
				}
			})
		},
	},
	beforeDestroy() {
		clearTimeout(this.timer);
	},
}

</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
</style>